/* src/Flipbook.css */
.flipbook-container {
    perspective: 1000px;
  }
  
  .flipbook {
    position: relative;
    width: 300px; /* Adjust as necessary */
    height: 400px; /* Adjust as necessary */
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .page {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
  }
  
  .front {
    background: #007bff; /* Front page color */
    transform: rotateY(0deg);
  }
  
  .back {
    background: #28a745; /* Back page color */
    transform: rotateY(180deg);
  }
  