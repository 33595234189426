.ad-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: start;
    overflow: auto;
    z-index: 1000;

}

.ads-container {
    display: flex;
    position: relative;
    top:15vh;
    justify-content: center;
    
    padding: 2rem;
    /* Transparent background to show the website */
    border-radius: 12px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
     /* Blur the background behind the ads */
     
}

.ad-box {
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: auto; /* Increased size for the ad */
}

.ad-image {
    max-width: auto;
    height: 80%;
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.5); /* Image shadow */
    border-radius: 5px;
}

.ad-close {
    position: absolute;
    top: 3px;
    right:105px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

.button_container {
    margin-top: 1.5rem;
}

.primary-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #FF5733; /* Orange button */

    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    border: none;
    border-radius: 50px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.primary-button:hover {
    background-color: #45a049;
    transform: scale(1.05); /* Slight scale effect */
}

.primary-button:active {
    background-color: #397d39;
    transform: scale(1);
}


@media only screen and (max-width: 375px) {
    .ads-container {
        top: 10vh !important;
        background-color: yellow; /* Optional for debugging */
    }
}
