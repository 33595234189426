.tab-border {
  padding-left: 25px;
  padding-right: 25px;

  border: 1px solid;
  box-shadow: 5px 10px 18px #888888;
}
.imgAbout {
  width: 700px;
}

.nav-list-style {
  background-color: #780000 !important;
  border-radius: 8px;
}
.nav-link {
  color: #b8b8b8;
}
.nav-link:hover {
  color: #b8b8b8 !important;
}
.nav-list-style li {
  padding: 5px;
  color: white;
  border: 1px solid white;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #780000 !important;
  color: white !important;
}

.partent {
  display: flex !important;
  gap: 10px;
}
.Box-About-1 {
  width: 700px;
}

.Box-About-2 {
  width: 700px;
  height: 500px;
  background-color: #f0f0f0;
}

.heading-news {
  text-align: center;
  background-color: #780000;
  color: white;
  padding: 10px;
}

.navLogos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 6rem 0;
}

.navLogos .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navLogos .logo h1 {
  font-size: 1.5rem;
  font-weight: 800;
}

header {
  background-image: url(../../asset/hero.jpg);
  background-position: top;
  background-size: cover;
  height: 30rem;
}

.headerParent {
  height: 30rem;
  background: rgba(0, 0, 0, 0.7);
}

.nav {
  margin: 1rem 0 0 0;
}

.nav .logo h1 {
  font-size: 1.5rem;
  font-weight: 800;
  color: white;
}

.heading {
  margin: 6rem 0 0 0;
}

.heading h1 {
  font-weight: 800;
  color: white;
}
