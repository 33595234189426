.nav-link:hover{
    color: inherit !important ; 
}

.news-box::-webkit-scrollbar  {
    width: 5px; /* Slim scrollbar width */
  }
  
  .news-box::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the track */
  }

  
  
  .news-box::-webkit-scrollbar-thumb {
    background: #888; /* Color of the thumb (scrollbar handle) */
    border-radius: 10px;
    height: 25px; /* Round corners of the scrollbar thumb */
  }
  
  .news-box::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color of the thumb on hover */
  }


  .gallery::-webkit-scrollbar{
    scrollbar-width: thin;

  }

  